import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    courses: [],
    total: 0,
  },
  getters: {
    courses: state => state.courses,
    total: state => state.total,
  },
  mutations: {
    SET_COURSES: (state, courses) => { state.courses = courses },
    SET_TOTAL: (state, total) => { state.total = total },
  },

  actions: {

    async getCoursesByStudentId({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/get_course_by_student', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_COURSES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
