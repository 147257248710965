import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import dropdown from '@/store/common/dropdown'
import creditClass from '@/store/credit-class'
import creditClassStudent from '@/store/credit-class-student'
import schedule from '@/store/schedule'
import turn from '@/store/turn'
import article from '@/store/article'
import creditClassMedia from '@/store/credit-class-media'
import studentCheckExamination from '@/store/student_check_examination'
import report from '@/store/report'
import diploma from '@/store/diploma'
import diplomaStudent from '@/store/diploma-student'
import studentCgpa from '@/store/student_cgpa'
import courseSemester from '@/store/course-semester'
import student from '@/store/student'
import accountFcmToken from '@/store/modules/account-fcm-token'
import notification from '@/store/modules/notification'
import forgotPassword from '@/store/forgot-password'
import curriculumVitae from '@/store/curriculum-vitae'
import payment from '@/store/payment'
import programme from '@/store/programme'
import programmeSubject from '@/store/programme-subject'
import programmeSubjectGroup from '@/store/programme-subject-group'
import registerSubject from './modules/register-subject'
import layout from './layout'
import study from './modules/study'
import auth from './modules/auth'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import studentRemakeExamination from '@/store/student_remake_examination'
import courses from '@/store/courses'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    layout,
    study,
    registerSubject,
    dropdown,
    creditClass,
    creditClassStudent,
    schedule,
    turn,
    article,
    studentCheckExamination,
    report,
    diploma,
    diplomaStudent,
    studentCgpa,
    courseSemester,
    creditClassMedia,
    student,
    accountFcmToken,
    notification,
    curriculumVitae,
    forgotPassword,
    payment,
    programme,
    programmeSubject,
    programmeSubjectGroup,
    studentRemakeExamination,
    courses
  },
  strict: process.env.DEV,
})
