import {ResourceCode} from "@/const/code";

export default [
  {
    path: '/examination',
    name: 'examination',
    component: () => import('@/views/examination/index.vue'),
    meta: {
      // resource: 'NEWS',
    },
  },
  {
    path: '/register-remake-examinations',
    name: 'registerRemakeExaminations',
    component: () => import('@/views/register_remake_examinations/Index.vue'),
    meta: {
      pageTitle: 'Đăng ký thi lại',
      resource: ResourceCode.REGISTER_REMAKE_EXAMINATIONS,
    },
  },
]
